import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from './Header'
import Home from './Home'
import Footer from './Footer'
import About from './About'
import Faq from './Faq'
import TermsAndOffer from './TermsAndOffer'
import Visit from './Visit'
import Grains from './Grains'
import Pulses from './Pulses'
import Spices from './Spices'
import CookingOils from './CookingOils'
import Masala from './Masala'
import BakingProducts from './BakingProducts'
import Grocerry from './Grocerry'
import SpicesDailyProducts from './SpicesDailyProducts'
import TryFruits from './TryFruits'
import ChocolatesBakery from './ChocolatesBakery'
import Cleaners from './Cleaners'
import Freshners from './Freshners'
import FabricCare from './FabricCare'
import CleaningProduct from './CleaningProduct'
import UseAndThrow from './UseAndThrow'
import ElectricAndLightning from './ElectricAndLightning'
import Learning from './Learning'
import HomeUtilities from './HomeUtilities'
import BrandedFood from './BrandedFood'
import PujaLiterature from './PujaLiterature'
import SkinCare from './SkinCare'
import OralCare from './OralCare'
import HairCare from './HairCare'
import HealthAndWellness from './HealthAndWellness'
import BabyCare from './BabyCare'
import Fragrances from './Fragrances'
import ShavingNeeds from './ShavingNeeds'
import MouthCare from './MouthCare'
import Error from './Error'
import FreshVegitables from './FreshVegitables'
import FreshFruits from './FreshFruits'
import TemperateFruits from './TemperateFruits'

const Routing = () => {
    return (
        <>

            <React.Fragment>

                <header className='sticky-top' style={{ backgroundColor: "#fff" }} >
                    <Header />
                </header>

                <main>

                    <Routes>
                        <Route to path="/" element={<Home />} />
                        <Route to path="/about" element={<About />} />
                        <Route to path="/faq" element={<Faq />} />
                        <Route to path="/termsandoffer" element={<TermsAndOffer />} />
                        <Route to path="/visit" element={<Visit />} />
                        <Route to path="/grains" element={<Grains />} />
                        <Route to path="/pulses" element={<Pulses />} />
                        <Route to path="/spices" element={<Spices />} />
                        <Route to path="/cookingoils" element={<CookingOils />} />
                        <Route to path="/masala" element={<Masala />} />
                        <Route to path="/bakingproducts" element={<BakingProducts />} />
                        <Route to path="/grocerry" element={<Grocerry />} />
                        <Route to path="/spicesdailyproducts" element={<SpicesDailyProducts />} />
                        <Route to path="/tryfruits" element={<TryFruits />} />
                        <Route to path="/chocolatesbakery" element={<ChocolatesBakery />} />
                        <Route to path="/cleaners" element={<Cleaners />} />
                        <Route to path="/fresheners" element={<Freshners />} />
                        <Route to path="/fabricare" element={<FabricCare />} />
                        <Route to path="/cleaningproduct" element={<CleaningProduct />} />
                        <Route to path="/Useandthrow" element={<UseAndThrow />} />
                        <Route to path="/electricandlightning" element={<ElectricAndLightning />} />
                        <Route to path="/learning" element={<Learning />} />
                        <Route to path="/homeutilities" element={<HomeUtilities />} />
                        <Route to path="/brandedfood" element={<BrandedFood />} />
                        <Route to path="/pujaliterature" element={<PujaLiterature />} />
                        <Route to path="/skincare" element={<SkinCare />} />
                        <Route to path="/oralcare" element={<OralCare />} />
                        <Route to path="/haircare" element={<HairCare />} />
                        <Route to path="/healthandwellness" element={<HealthAndWellness />} />
                        <Route to path="/babycare" element={<BabyCare />} />
                        <Route to path="/fragrances" element={<Fragrances />} />
                        <Route to path="/shavingneeds" element={<ShavingNeeds />} />
                        <Route to path="/mouthcare" element={<MouthCare />} />
                        <Route to path="/freshvegitables" element={<FreshVegitables />} />
                        <Route to path="/freshfruits" element={<FreshFruits />} />
                        <Route to path="/temperatefruits" element={<TemperateFruits />} />


                        <Route to path="*" element={<Error />} />

                    </Routes>

                </main>

                <footer>
                    <Footer/>
                </footer>

            </React.Fragment>

        </>
    )
}

export default Routing