import React from 'react'
import { NavLink } from 'react-router-dom'

const HealthAndWellness = () => {
    return (

        <>

            <section>
                <div className="container-fluid FontFamilyMontSerret">
                    <div className=' pt-3'>
                        <h6 className='mt-2 ms-lg-5 ps-lg-5' style={{ fontSize: "16px" }} > <NavLink to='/' className='text-decoration-none YESEVA' style={{ color: "black" }} > <span> Home </span>  </NavLink> <i class="fa-solid fa-angle-right"></i> <span> Personal Care </span> <i class="fa-solid fa-angle-right"></i> <NavLink to='/healthandwellness' className='text-decoration-none' style={{ color: "#00afc6" }} > <span> Health And Wellness </span> </NavLink> </h6>
                    </div>
                    <div className="container p-3">
                        <div>
                            <h2 className='pt-3 pb-3' style={{ fontSize: "24px" }} >Showing Products for <b className='' style={{ color: "#00afc6" }} > Health And Wellness </b> </h2>
                        </div>
                        <div className="row g-4 p-3">

                            <div className="col-lg-4 ">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/HandSanitizer.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} >  Lifebuoy Alcohol Based Germ Protection Hand Sanitizer - 50 ml </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.0</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 25.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 25.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Digestion.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Dabur Pudin Hara Active Digestion Solution - 30ml </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.15</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 46.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 55.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/PainRelief.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "14px" }} > Moov Fast Pain Relief Cream - 50g | Suitable for Back Pain, Muscle Pain, Joint Pain, Knee Pain </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.22</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 105.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 205.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Tablets.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Himalaya Ashvagandha - 60 Tablets | Stress Relief  </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.15</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 187.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 220.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Boroplus.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Boroplus Antiseptic Cream Provides 24Hrs, Tulsi And Aloe Vera, 120Ml </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.17</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 153.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 185.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/FaceMask.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > L'Oréal Paris Revitalift Crystal Micro-Essence Sheet Mask, Face Treatment, 25g </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.20</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 120.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 150.00 </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default HealthAndWellness