import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Header.css"

const Header = () => {
    return (
        <>

            <section>
                <div className='container-fluid d-none d-lg-block d-block'>
                    <div className="container "  >
                        <div className="row d-flex ">

                            <nav className='navbar navbar-expand-lg  p-0 d-flex justify-content-lg-around  align-items-center ' style={{ fontWeight: "400" }}>
                                <div className='p-2'>
                                    <div className=''>
                                        <NavLink to="/"><img className='' style={{ height: "2.2rem" }} src={require("../img/LOGONEW.png")} alt="Jay Kisan Smart Mall_logo" /></NavLink>
                                    </div>
                                </div>

                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul class="navbar-nav FontFamilyMontSerret w-100 d-flex justify-content-lg-end align-items-lg-center text-lg-start">

                                        <li className=" d-flex justify-content-lg-center ms-lg-3" >
                                            <NavLink className="text-decoration-none" to='' >  <h6 className='ms-lg-2 FontMontSerret BOOKINGHEADER ' style={{ fontSize: "16px" }} > Contact : 8551964221  </h6> </NavLink>
                                        </li>
                                        <li className=" d-flex justify-content-lg-center ms-lg-3" >
                                            <NavLink className="text-decoration-none" to='' >  <h6 className='ms-lg-2 FontMontSerret BOOKINGHEADER ' style={{ fontSize: "16px" }} > Email : jaykisansmartmall@gmail.com  </h6> </NavLink>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>

                <div className='container-fluid d-lg-none d-none ' style={{ borderBottom: '1px solid rgb(204, 220, 228)' }}>
                    <div className="container "  >
                        <div className="row d-flex ">

                            <nav className='navbar navbar-expand-lg  p-0 d-flex justify-content-lg-around  align-items-center ' style={{ fontWeight: "400" }}>
                                <div className='p-2'>
                                    <div className=''>
                                        <NavLink to="/"><img className='' style={{ height: "2.2rem" }} src={require("../img/logo.png")} alt="Jay Kisan Smart Mall_logo" /></NavLink>
                                    </div>
                                </div>

                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul class="navbar-nav FontFamilyMontSerret w-100 d-flex justify-content-lg-end align-items-lg-center text-lg-start">

                                        <li className="" >
                                            <h6 className='p-1' style={{ color: "#282828", fontSize: "16px", fontWeight: "500" }}> About </h6>
                                        </li>

                                        <li className=" d-flex justify-content-lg-center ms-lg-3" >
                                            <NavLink className="text-decoration-none" to='' >  <h6 className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "16px" }} > Contact : 8551964221  </h6> </NavLink>
                                        </li>
                                        <li className=" d-flex justify-content-lg-center ms-lg-3" >
                                            <NavLink className="text-decoration-none" to='' >  <h6 className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "16px" }} > Email : jaykisansmartmall@gmail.com  </h6> </NavLink>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <nav className='navbar navbar-expand-lg p-0 m-0  d-none d-md-block' style={{ backgroundColor: "#283045" }} >
                    <div className="container-fluid"    >

                        <button className="navbar-toggler " type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa-solid fa-bars"></i>
                        </button>

                        <div class="collapse navbar-collapse FontFamilyMontSerret" id="navbarNavDropdown">
                            <ul class="navbar-nav container w-100"  >
                                <li className=" p-lg-2  nav-items #submenu" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle nav-i HEADERHOVER" id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"> Foodgrain , Oil & Masala </div>
                                    <ul className="dropdown-menu w-100  collapse p-0" aria-labelledby="mega-one" style={{ zIndex: "6", border: "none", backgroundColor: "unset", }} id="submenu">
                                        <li className='nav-items   text-white'  >
                                            <div className='container'  >
                                                <div className="row FontFamilyMontSerret">
                                                    <div className="col-lg-12  shadow-lg" style={{ backgroundColor: "#fff" }}>
                                                        <div className="row" style={{ height: "500px", overflow: "auto" }} >
                                                            <div className="col-lg-3 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none' to="/grains" >
                                                                    <div className='dropdown-item  mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Grains </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grains" >
                                                                    <div className='dropdown-item   HEADERK12' > Wheat </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grains" >
                                                                    <div className='dropdown-item   HEADERK12' > Raw Corn </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grains" >
                                                                    <div className='dropdown-item   HEADERK12' > Pearl Millet </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grains" >
                                                                    <div className='dropdown-item   HEADERK12' > Sorghum </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grains" >
                                                                    <div className='dropdown-item   HEADERK12' > Papad Nagali </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Pulses </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Moong </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Arhar </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Chickpeas </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Masur </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Matki ( Moth Beans ) </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Chawali ( Black Eyed Beans ) </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4 mb-3" style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item mt-2  HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Pulses </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Kidney Beans </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Soya Bean </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Yellow Pigeon Beans </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Green Gram ( Moong Dal ) </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pulses" >
                                                                    <div className='dropdown-item   HEADERK12' > Red Lentils ( Masoor ) </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none ' to="/spices">
                                                                    <div className='dropdown-item  mt-2 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Spices </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spices" >
                                                                    <div className='dropdown-item   HEADERK12' > Black Pepper </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spices" >
                                                                    <div className='dropdown-item   HEADERK12' > Cardamon ( Lavang ) </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spices" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Turmeric </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spices" >
                                                                    <div className='dropdown-item   HEADERK12' > Yellow Mustard </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spices" >
                                                                    <div className='dropdown-item   HEADERK12' > Ground Cumin </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spices" >
                                                                    <div className='dropdown-item   HEADERK12' > Cinnamon </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spices" >
                                                                    <div className='dropdown-item   HEADERK12' > Root Ginger Powder </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none ' to="/cookingoils">
                                                                    <div className='dropdown-item  mt-2 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Cooking Oils </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cookingoils" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Sunflower Oil </div>

                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cookingoils" >
                                                                    <div className='dropdown-item   HEADERK12' > Musterd Oil </div>

                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cookingoils" >
                                                                    <div className='dropdown-item   HEADERK12' > Groundnut Oil </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cookingoils" >
                                                                    <div className='dropdown-item   HEADERK12' > Palm Oil </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cookingoils" >
                                                                    <div className='dropdown-item   HEADERK12' > Soyabeen Oil </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none ' to="/cookingoils">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Healthy Oils </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cookingoils" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Rice Bran Oil </div>

                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cookingoils" >
                                                                    <div className='dropdown-item   HEADERK12' > Blended Oil </div>

                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cookingoils" >
                                                                    <div className='dropdown-item   HEADERK12' > Extra Light Olive Oil </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none' to="/masala" >
                                                                    <div className='dropdown-item mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > <b>Masala</b> </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/masala" >
                                                                    <div className='dropdown-item   HEADERK12' > Coriander Powder </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/masala" >
                                                                    <div className='dropdown-item   HEADERK12' > Chili Powder </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/masala" >
                                                                    <div className='dropdown-item   HEADERK12' > Dry Mango Powder </div>
                                                                </NavLink>


                                                                <NavLink className='text-decoration-none' to="/masala" >
                                                                    <div className='dropdown-item   HEADERK12' > Meat Masala </div>

                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/masala" >
                                                                    <div className='dropdown-item   HEADERK12' > Chole Masala </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/masala" >
                                                                    <div className='dropdown-item   HEADERK12' > PavBhaji Masala </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/masala" >
                                                                    <div className='dropdown-item   HEADERK12' > Paneer Masala </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/masala" >
                                                                    <div className='dropdown-item   HEADERK12' > Hing </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/bakingproducts" >
                                                                    <div className='dropdown-item mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Baking Products </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/bakingproducts" >
                                                                    <div className='dropdown-item   HEADERK12' > Baking Powder </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/bakingproducts" >
                                                                    <div className='dropdown-item   HEADERK12' > Baking Soda </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/bakingproducts" >
                                                                    <div className='dropdown-item   HEADERK12' > Custured Powder </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/bakingproducts" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Curry Powder </div>
                                                                </NavLink>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <li className=" p-lg-2  nav-items #submenu" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle nav-i HEADERHOVER" id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"> Daily Products </div>
                                    <ul className="dropdown-menu w-100  collapse p-0" aria-labelledby="mega-one" style={{ zIndex: "6", border: "none", backgroundColor: "unset", }} id="submenu">
                                        <li className='nav-items   text-white'  >
                                            <div className='container'  >
                                                <div className="row FontFamilyMontSerret">
                                                    <div className="col-lg-12  shadow-lg" style={{ backgroundColor: "#fff" }}>
                                                        <div className="row" style={{ height: "340px", overflow: "auto" }} >
                                                            <div className="col-lg-3 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none ' to="/grocerry">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Grocery </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Sugar </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Jaggery </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Peanuts </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Poha  </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item   HEADERK12' > Maida </div>

                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item   HEADERK12' > Rava </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4 mb-3" style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item mt-3 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Grocery </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item   HEADERK12' > Dry Dates </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item   HEADERK12' > Coconut Meal </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item   HEADERK12' > Jeera </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item   HEADERK12' > Salt </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item   HEADERK12' > Coffe </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/grocerry" >
                                                                    <div className='dropdown-item   HEADERK12' > Puffed Rice </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4  mb-3">

                                                                <NavLink className='text-decoration-none ' to="/spicesdailyproducts">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Spices </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spicesdailyproducts" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Onion Paste </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spicesdailyproducts" >
                                                                    <div className='dropdown-item   HEADERK12' > Chat Masala </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spicesdailyproducts" >
                                                                    <div className='dropdown-item   HEADERK12' > Kasturi Methi </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/spicesdailyproducts" >
                                                                    <div className='dropdown-item   HEADERK12' > Sendha Salt </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4  mb-3">

                                                                <NavLink className='text-decoration-none ' to="/tryfruits">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Try Fruits </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/tryfruits" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Ghe ( Cow Ghee ) </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/tryfruits" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Butter </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/tryfruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Almonds </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/tryfruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Cashew Nut </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/tryfruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Pistachio </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/tryfruits" >
                                                                    <div className='dropdown-item   HEADERK12' >  raisins </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/tryfruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Anjeer </div>
                                                                </NavLink>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <li className=" p-lg-2  nav-items #submenu" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle nav-i HEADERHOVER" id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"> Fruits & Vegitables </div>
                                    <ul className="dropdown-menu w-100  collapse p-0" aria-labelledby="mega-one" style={{ zIndex: "6", border: "none", backgroundColor: "unset", }} id="submenu">
                                        <li className='nav-items   text-white'  >
                                            <div className='container'  >
                                                <div className="row FontFamilyMontSerret">
                                                    <div className="col-lg-12  shadow-lg" style={{ backgroundColor: "#fff" }}>
                                                        <div className="row" style={{ height: "450px", overflow: "auto" }} >
                                                            <div className="col-lg-4 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none ' to="/freshvegitables">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Fresh Vegitables </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshvegitables" >
                                                                    <div className='dropdown-item   HEADERK12' > Tomato </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshvegitables" >
                                                                    <div className='dropdown-item   HEADERK12' > Onion </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshvegitables" >
                                                                    <div className='dropdown-item   HEADERK12' > Potato </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshvegitables" >
                                                                    <div className='dropdown-item   HEADERK12' > Lemon </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshvegitables" >
                                                                    <div className='dropdown-item   HEADERK12' > Carrot </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshvegitables" >
                                                                    <div className='dropdown-item   HEADERK12' > Lady Finger (Bhindi) </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-4 mb-3" style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none ' to="/freshfruits">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Fresh Fruits </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshfruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Raw Papaya </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshfruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Bananas </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshfruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Mango </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshfruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Nagpur Orange </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/freshfruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Kinnow </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-4 mb-3" style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none' to="/temperatefruits" >
                                                                    <div className='dropdown-item mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer",fontWeight:"800" }} > Temperate Fruits </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/temperatefruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Grapes </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/temperatefruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Pine Apple </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/temperatefruits" >
                                                                    <div className='dropdown-item   HEADERK12' > Custard Apple </div>
                                                                </NavLink>

                                                            </div>

                                                            

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <li className=" p-lg-2  nav-items #submenu" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle nav-i HEADERHOVER" id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"> Chocolates </div>
                                    <ul className="dropdown-menu w-100  collapse p-0" aria-labelledby="mega-one" style={{ zIndex: "6", border: "none", backgroundColor: "unset", }} id="submenu">
                                        <li className='nav-items   text-white'  >
                                            <div className='container'  >
                                                <div className="row FontFamilyMontSerret">
                                                    <div className="col-lg-12  shadow-lg" style={{ backgroundColor: "#fff" }}>
                                                        <div className="row"  >
                                                            <div className="col-lg-6 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none ' to="/chocolatesbakery">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Chocolates & Bakery </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/chocolatesbakery" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Cookies & Biscotti </div>
                                                                </NavLink>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <li className=" p-lg-2  nav-items #submenu" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle nav-i HEADERHOVER" id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"> Home Care </div>
                                    <ul className="dropdown-menu w-100  collapse p-0" aria-labelledby="mega-one" style={{ zIndex: "6", border: "none", backgroundColor: "unset", }} id="submenu">
                                        <li className='nav-items   text-white'  >
                                            <div className='container'  >
                                                <div className="row FontFamilyMontSerret">
                                                    <div className="col-lg-12  shadow-lg" style={{ backgroundColor: "#fff" }}>
                                                        <div className="row" style={{ height: "420px", overflow: "auto" }} >
                                                            <div className="col-lg-4 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none ' to="/cleaners">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Cleaners </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cleaners" >
                                                                    <div className='dropdown-item  HEADERK12 ' >  Utensil Cleaner Bar </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cleaners" >
                                                                    <div className='dropdown-item  HEADERK12 ' >  Toilet Cleaners </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cleaners" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Floor Cleaners </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cleaners" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Utensil Cleaner Gel </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cleaners" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Kitchen & Glass Cleaners </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fresheners" >
                                                                    <div className='dropdown-item  mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} >  Fresheners </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fresheners" >
                                                                    <div className='dropdown-item   HEADERK12' > Solid Freshners </div>

                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fresheners" >
                                                                    <div className='dropdown-item   HEADERK12' > Gel Freshners </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fresheners" >
                                                                    <div className='dropdown-item   HEADERK12' > Air Fresheners Spray </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fresheners" >
                                                                    <div className='dropdown-item   HEADERK12' > Car Freshners </div>
                                                                </NavLink>


                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-4 mb-3" style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none' to="/fabricare" >
                                                                    <div className='dropdown-item mt-3 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Fabric Care </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fabricare" >
                                                                    <div className='dropdown-item   HEADERK12' > Bucket Wash Detergent </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fabricare" >
                                                                    <div className='dropdown-item   HEADERK12' > Blue,Fabric Conditioner & Whitner </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fabricare" >
                                                                    <div className='dropdown-item   HEADERK12' > Detergent Bar </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fabricare" >
                                                                    <div className='dropdown-item   HEADERK12' > Top Load Detergent Liquid </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fabricare" >
                                                                    <div className='dropdown-item   HEADERK12' >  Top Load Detergent Powder </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fabricare" >
                                                                    <div className='dropdown-item   HEADERK12' > Front Load Detergent Powder </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fabricare" >
                                                                    <div className='dropdown-item   HEADERK12' >  Front Load Detergent Liquid </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-4 mb-3" style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none' to="/cleaningproduct" >
                                                                    <div className='dropdown-item mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Cleaning Products </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cleaningproduct" >
                                                                    <div className='dropdown-item   HEADERK12' > Utensils Soap </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cleaningproduct" >
                                                                    <div className='dropdown-item   HEADERK12' >  Pitambari Powder</div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cleaningproduct" >
                                                                    <div className='dropdown-item   HEADERK12' > Clothes powder / Soap </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/cleaningproduct" >
                                                                    <div className='dropdown-item   HEADERK12' > Toilet Cleaner </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/Useandthrow" >
                                                                    <div className='dropdown-item mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Use & Throw Products </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/Useandthrow" >
                                                                    <div className='dropdown-item   HEADERK12' > Plastic </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/Useandthrow" >
                                                                    <div className='dropdown-item   HEADERK12' > Glass </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/Useandthrow" >
                                                                    <div className='dropdown-item   HEADERK12' > Bowl </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/Useandthrow" >
                                                                    <div className='dropdown-item   HEADERK12' > Plates </div>
                                                                </NavLink>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <li className=" p-lg-2  nav-items #submenu" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle nav-i HEADERHOVER" id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"> Personal Care </div>
                                    <ul className="dropdown-menu w-100  collapse p-0" aria-labelledby="mega-one" style={{ zIndex: "6", border: "none", backgroundColor: "unset", }} id="submenu">
                                        <li className='nav-items   text-white'  >
                                            <div className='container'  >
                                                <div className="row FontFamilyMontSerret">
                                                    <div className="col-lg-12  shadow-lg" style={{ backgroundColor: "#fff" }}>
                                                        <div className="row" style={{ height: "500px", overflow: "auto" }} >
                                                            <div className="col-lg-3 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none ' to="/skincare">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Skin Care </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/skincare" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Popular Soaps </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/skincare" >
                                                                    <div className='dropdown-item   HEADERK12' > Hand Wash </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/skincare" >
                                                                    <div className='dropdown-item   HEADERK12' > Premium Soaps </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/skincare" >
                                                                    <div className='dropdown-item   HEADERK12' > Face Wash & Scrub </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/skincare" >
                                                                    <div className='dropdown-item   HEADERK12' > Body Wash </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/skincare" >
                                                                    <div className='dropdown-item   HEADERK12' > Body Lotions & Creams </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/skincare" >
                                                                    <div className='dropdown-item   HEADERK12' >  Skin Wipes & Accessories </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/oralcare" >
                                                                    <div className='dropdown-item mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Oral Care </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/oralcare" >
                                                                    <div className='dropdown-item   HEADERK12' > Dental Cream </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/oralcare" >
                                                                    <div className='dropdown-item   HEADERK12' > Gel Tooth Paste </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/oralcare" >
                                                                    <div className='dropdown-item   HEADERK12' > Tooth Brushes </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/oralcare" >
                                                                    <div className='dropdown-item   HEADERK12' > Mouth Wash </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4 mb-3" style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none' to="/haircare" >
                                                                    <div className='dropdown-item mt-3 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Hair Care </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/haircare" >
                                                                    <div className='dropdown-item   HEADERK12' > Hair Oils </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/haircare" >
                                                                    <div className='dropdown-item   HEADERK12' > Hair Shampoo </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/haircare" >
                                                                    <div className='dropdown-item   HEADERK12' > Hair Conditioner </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/haircare" >
                                                                    <div className='dropdown-item   HEADERK12' > Hair Colour </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/haircare" >
                                                                    <div className='dropdown-item   HEADERK12' > Hair Gel , Serum & Cream </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/haircare" >
                                                                    <div className='dropdown-item   HEADERK12' > Combs & Brushes </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/healthandwellness" >
                                                                    <div className='dropdown-item mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Health & Wellness </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/healthandwellness" >
                                                                    <div className='dropdown-item   HEADERK12' > Sanitizer & Safety </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/healthandwellness" >
                                                                    <div className='dropdown-item   HEADERK12' > Antacids & Digestives </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/healthandwellness" >
                                                                    <div className='dropdown-item   HEADERK12' > Pain Relievers </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/healthandwellness" >
                                                                    <div className='dropdown-item   HEADERK12' > Health Supplements </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/healthandwellness" >
                                                                    <div className='dropdown-item   HEADERK12' > Antiseptic Lotions & Creams </div>
                                                                </NavLink>


                                                                <NavLink className='text-decoration-none' to="/healthandwellness" >
                                                                    <div className='dropdown-item   HEADERK12' > Face Mask </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4  mb-3">

                                                                <NavLink className='text-decoration-none ' to="/babycare">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Baby Care </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/babycare" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Pant Style Diaper </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/babycare" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Baby Soaps & Shampoo </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/babycare" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Baby Wipes </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/babycare" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Baby Powder & Baby Oil </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/babycare" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Baby Lotion & Cream </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none ' to="/fragrances">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Fragrances </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fragrances" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Body Deodorants </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fragrances" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Talcum Powder </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/fragrances" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Perfumes </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none ' to="/shavingneeds">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Shaving Needs </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/shavingneeds" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Razor </div>

                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/shavingneeds" >
                                                                    <div className='dropdown-item   HEADERK12' > Shaving Foam & Gel </div>

                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/shavingneeds" >
                                                                    <div className='dropdown-item   HEADERK12' >  Cartridges & Blades </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/shavingneeds" >
                                                                    <div className='dropdown-item   HEADERK12' > Shaving Cream </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/shavingneeds" >
                                                                    <div className='dropdown-item   HEADERK12' > After Shave Lotion </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/mouthcare" >
                                                                    <div className='dropdown-item mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Mouth Care </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/mouthcare" >
                                                                    <div className='dropdown-item   HEADERK12' > Tooth Paste </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/mouthcare" >
                                                                    <div className='dropdown-item   HEADERK12' > Danta Manjan </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/mouthcare" >
                                                                    <div className='dropdown-item   HEADERK12' > Tooth Brush </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/mouthcare" >
                                                                    <div className='dropdown-item   HEADERK12' > Bathing Soaps </div>
                                                                </NavLink>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <li className=" p-lg-2  nav-items #submenu" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle nav-i HEADERHOVER" id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"> Home & Kitchen </div>
                                    <ul className="dropdown-menu w-100  collapse p-0" aria-labelledby="mega-one" style={{ zIndex: "6", border: "none", backgroundColor: "unset", }} id="submenu">
                                        <li className='nav-items   text-white'  >
                                            <div className='container'  >
                                                <div className="row FontFamilyMontSerret">
                                                    <div className="col-lg-12  shadow-lg" style={{ backgroundColor: "#fff" }}>
                                                        <div className="row" style={{ height: "400px", overflow: "auto" }} >
                                                            <div className="col-lg-3 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none ' to="/electricandlightning">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Electric & Lightning </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/electricandlightning" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Batteries </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/electricandlightning" >
                                                                    <div className='dropdown-item   HEADERK12' > Bulbs & Tubes </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/electricandlightning" >
                                                                    <div className='dropdown-item   HEADERK12' > Electrical Accessories </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/learning" >
                                                                    <div className='dropdown-item  mt-2 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Learning </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/learning" >
                                                                    <div className='dropdown-item   HEADERK12' > Stationery </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/learning" >
                                                                    <div className='dropdown-item   HEADERK12' > NoteBooks </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/learning" >
                                                                    <div className='dropdown-item   HEADERK12' > Office & School Needs </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/learning" >
                                                                    <div className='dropdown-item   HEADERK12' > Drawing Accessories </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none ' to="/homeutilities">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Home Utilities </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/homeutilities" >
                                                                    <div className='dropdown-item  HEADERK12 ' > Dusters, Scrubs And Wipes </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/homeutilities" >
                                                                    <div className='dropdown-item   HEADERK12' > Kitchen Implements </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/homeutilities" >
                                                                    <div className='dropdown-item   HEADERK12' > Plastic Bins </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/homeutilities" >
                                                                    <div className='dropdown-item   HEADERK12' > Mat </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/homeutilities" >
                                                                    <div className='dropdown-item   HEADERK12' > Dohar and Bedsheet </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/homeutilities" >
                                                                    <div className='dropdown-item   HEADERK12' > Brooms & Brushes </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/homeutilities" >
                                                                    <div className='dropdown-item   HEADERK12' > Glassware </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/homeutilities" >
                                                                    <div className='dropdown-item   HEADERK12' > Towel </div>
                                                                </NavLink>


                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4 mb-3">

                                                                <NavLink className='text-decoration-none ' to="/brandedfood">
                                                                    <div className='dropdown-item  mt-3 mb-1' >
                                                                        <b className='' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Branded Food </b>
                                                                    </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/brandedfood" >
                                                                    <div className='dropdown-item   HEADERK12' > Pasta </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/brandedfood" >
                                                                    <div className='dropdown-item   HEADERK12' > SoyaSauce </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/brandedfood" >
                                                                    <div className='dropdown-item   HEADERK12' > Wafers </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/brandedfood" >
                                                                    <div className='dropdown-item   HEADERK12' > Chili Sauce </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/brandedfood" >
                                                                    <div className='dropdown-item   HEADERK12' > Mix Chiwda ( Farsan )  </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/brandedfood" >
                                                                    <div className='dropdown-item   HEADERK12' > Tomato Sauce </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/brandedfood" >
                                                                    <div className='dropdown-item   HEADERK12' > Pizza Sauce </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/brandedfood" >
                                                                    <div className='dropdown-item   HEADERK12' > Pickle </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/brandedfood" >
                                                                    <div className='dropdown-item   HEADERK12' > Mayo Sauce </div>
                                                                </NavLink>

                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-4 mb-3" style={{ fontSize: "14px" }}>

                                                                <NavLink className='text-decoration-none' to="/pujaliterature" >
                                                                    <div className='dropdown-item mt-3 mb-1 HEADERK12' style={{ color: "rgb(246, 185, 132)", fontSize: "15px", cursor: "pointer" }} > Puja Literature </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pujaliterature" >
                                                                    <div className='dropdown-item   HEADERK12' > Agarbatti </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pujaliterature" >
                                                                    <div className='dropdown-item   HEADERK12' > Dhop </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pujaliterature" >
                                                                    <div className='dropdown-item   HEADERK12' > Haldi  </div>
                                                                </NavLink>

                                                                <NavLink className='text-decoration-none' to="/pujaliterature" >
                                                                    <div className='dropdown-item   HEADERK12' > Kapur </div>
                                                                </NavLink>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>

                <nav className="navbar navbar-expand-md  header-content d-md-none d-block" >
                    <div className="container-fluid">
                        {/* <div className='container'>
                            <div className='row d-flex  align-items-center' style={{ height: "100vh" }}>
                                <h2>icloudsoft</h2>
                            </div>
                        </div> */}







                        {/* <div className=''>
                            <NavLink to="/">
                                <img className='' style={{ height: "3rem" }} src={require("../img/logo.png")} alt="Jay Kisan Smart Mall_logo" />
                            </NavLink>
                        </div>
                        <button className="navbar-toggler bg-light" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa-solid fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse mt-2" style={{ backgroundColor: "#1c252a" }} id="navbarNavDropdown">
                            <ul className="navbar-nav  FONT d-flex justify-content-lg-around align-items-lg-center w-100 p-2"  >
                                <li className='nav-item pb-2' style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} >
                                    <NavLink className=" text-white text-decoration-none fs-6  " to="/" > <b className=''  > Foodgrain , Oil & Masala </b> </NavLink>
                                </li>

                                <li className='nav-item pb-2' style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} >
                                    <NavLink className=" text-white text-decoration-none fs-6  " to="/" > <b className=''  > Dairy & Bakery </b> </NavLink>
                                </li>

                                <li className='nav-item pb-2' style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} >
                                    <NavLink className=" text-white text-decoration-none fs-6  " to="/" > <b className=''  > Fruits & Vegitables </b> </NavLink>
                                </li>

                                <li className='nav-item pb-2' style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} >
                                    <NavLink className=" text-white text-decoration-none fs-6  " to="/" > <b className=''  > Chocolates </b> </NavLink>
                                </li>

                                <li className='nav-item pb-2' style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} >
                                    <NavLink className=" text-white text-decoration-none fs-6  " to="/" > <b className=''  > Home Care </b> </NavLink>
                                </li>

                                <li className='nav-item pb-2' style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} >
                                    <NavLink className=" text-white text-decoration-none fs-6  " to="/" > <b className=''  > Personal Care </b> </NavLink>
                                </li>

                                <li className='nav-item pb-2' style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} >
                                    <NavLink className=" text-white text-decoration-none fs-6  " to="/" > <b className=''  > Home & Kitchen </b> </NavLink>
                                </li>

                            </ul>
                        </div> */}
                    </div>
                </nav>
            </section>

        </>
    )
}

export default Header