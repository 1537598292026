import React from 'react'
import "./Comman.css"

const Home = () => {

  return (

    <>

      <section>
        <div className="container-fluid p-0 d-none d-md-block " >
          <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
            <div className="carousel-inner FontFamilyMontSerret">
              <div className="carousel-item active position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../img/HomeBG1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'right' }}>

              </div>

              <div className="carousel-item  position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../img/HomeBG2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'right' }}>

              </div>

              <div className="carousel-item  position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../img/HomeBG3.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'right' }}>

              </div>

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon  " style={{ height: "65px" }} aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon " style={{ height: "65px" }} aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div className="container-fluid p-0 d-md-none d-block" >
        </div>
      </section>

      <section>
        <div className="container-fluid d-none d-md-block ">
          <div className="container p-lg-4">
            <div className=' mb-lg-5 pt-4'>
              <h2 className='YESEVA'> Best Price Ever </h2>
            </div>

            <div className="row g-4 d-flex justify-content-center align-items-center FontFamilyMontSerret">
              <div className="col-lg-3 col-md-6">
                <div className='position-relative h-100' >
                  <img className='w-100' style={{ height: "330px", borderRadius: "0px 0px 0px 0px" }} src={require("../img/1.png")} alt='' />
                  <div className='position-absolute' style={{ left: "10%", top: "20%" }} >
                    <h2 className='' style={{ fontSize: "23px", color: "#fff", fontWeight: "800" }} >Best Price Ever !</h2>
                    <p className='mt-3 mb-4' style={{ fontSize: "15px", color: "#fff", fontWeight: "400" }} > Find the best deals on your favourite products </p>
                    <button className='p-2 ' style={{ backgroundColor: "#fff", color: "rgb(44, 191, 206)", border: "none", borderRadius: "20px 20px 20px 20px", height: "45px", width: "50%" }} > <b className=''  > View All </b> </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/wheat.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "14px" }} > Aashirvaad Superior & Fortune Chakki Fresh Atta, 5kg   </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "14px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.10</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 250.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹250.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/rowcorn.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "14px" }} > Saara HERBAL FRESH Corn Kernel Seeds 500g  </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.24</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 224.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹300.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/perl.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "14px" }} > Fortune Bajra Sabut (Pearl Millet Whole) 1Kg Packing</h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.24</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 186.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹266.00 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid p-0 d-md-none d-block" >
        </div>

      </section>

      <section>
        <div className="container-fluid d-none d-md-block ">
          <div className="container p-lg-4">

            <div className="row g-4 mt-2 d-flex justify-content-center align-items-center FontFamilyMontSerret">
              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/sugar.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} >  Parry's White Label Sugar, 5kg paking </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "14px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.25</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 225.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹300.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/Jaggery.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Vedaka Organic Jaggery Powder, 1kg Jar. </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.49</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 225.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹115.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/Peanuts.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Pro Nature 100% Organic Raw Peanuts, 500g </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.15</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 149.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 165.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/Poha.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Tata Sampann, High in Fibre Poha(Thick), 500g </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.23</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 37.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 48.00 </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container-fluid p-0 d-md-none d-block" >
        </div>

      </section>

      <section>
        <div className="container-fluid d-none d-md-block ">
          <div className="container p-lg-4">

            <div className="row g-4 mt-2 d-flex justify-content-center align-items-center FontFamilyMontSerret">
              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/Tomatoes.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} >  Pro Nature 100% Organic Tomatoes,1kg   </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "14px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.25</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 325.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹300.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/Onion.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Pro Nature 100% Organic Onion, 1kg </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.49</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 45.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹55.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/Pine_Apple.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Fresho Pineapple, 1 pc 800 g -1000 g </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.47</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 55.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹107.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/Mini_Orange.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Fresho Mini Orange Imported (Loose), 500 g </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.27</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 184.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 252.00 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid p-0 d-md-none d-block" >
        </div>

      </section>

      <section>
        <div className="container-fluid d-none d-md-block ">
          <div className="container p-lg-4">

            <div className="row g-4 mt-2 d-flex justify-content-center align-items-center FontFamilyMontSerret">
              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/ExoDishwashBar.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Exo Dishwash Bar, 360g (90g x 3 +1 Bar Free) </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.13</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 26.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹30.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/ToiletCleaner.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} >  Toilet Cleaner - 520ml*3 (Buy 2 Get 1 Free) </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.28</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 166.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 230.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/FloorCleaner.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Floor Cleaner - 2 L|Kills 99.9% Germs </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.55</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 179.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 400.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/VimelLemon.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Vim Dishwash Liquid With Lemon Fragrance 500 ml  </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.7</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 116.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 125.00 </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container-fluid p-0 d-md-none d-block" >
        </div>

      </section>

      <section>
        <div className="container-fluid d-none d-md-block ">
          <div className="container p-lg-4 mb-5">

            <div className="row g-4 mt-2 d-flex justify-content-center align-items-center FontFamilyMontSerret">
              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/PopularSoap.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} >  Pears Pure & Gentle Soap Bar (Combo Pack of 8) </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.23</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 523.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 680.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/Handwash.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Dettol Liquid Handwash Refill – Skincare Hand Wash- 1500ml </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.25</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 165.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 220.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/Batteries.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Duracell Alkaline AA Batteries, Pack of 10 </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.15</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 187.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 220.00 </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                  <div className='text-center mb-3'>
                    <img className='' style={{ height: "130px" }} src={require("../img/FaceWash.png")} alt='' />
                  </div>
                  <div className='p-2'>
                    <h6 className='' style={{ fontSize: "15px" }} > Lotus Herbals Strawberry & Aloe Vera Face Wash | 120g </h6>
                  </div>
                  <div className='p-2'>
                    <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                    <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.32</span>
                  </div>
                  <div className='p-2'>
                    <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 143.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 220.00 </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container-fluid p-0 d-md-none d-block" >
        </div>

      </section>

      <section>
        <div className="container-fluid d-none d-md-block " style={{ backgroundColor: "#fff" }} >
          <div className="container text-center mb-lg-5 pb-5  p-3 p-lg-5 FontFamilyMontSerret">
            <div className='mt-2 mb-3'>
              <h2 className='' style={{ fontWeight: "600" }} >Why Choose Jay Kisan Smart Mall</h2>
            </div>
            <div className='row d-flex p-lg-4 justify-content-center align-items-center'>

              <div className="col-lg-4">
                <div>
                  <img className='' src={require("../img/why1.png")} alt='' />
                  <h3 className='mt-lg-2 pt-1' style={{ fontSize: "24px", fontWeight: "600" }} >Quality</h3>
                  <h6 className='' style={{ color: "#999", fontSize: "14px" }} >You can trust</h6>
                </div>
              </div>

              <div className="col-lg-4">
                <div>
                  <img className='' src={require("../img/why2.png")} alt='' />
                  <h3 className='mt-lg-2 pt-1' style={{ fontSize: "24px", fontWeight: "600" }} > Best Discount Offer </h3>
                  <h6 className='' style={{ color: "#999", fontSize: "14px" }} > 50% discount offer </h6>
                </div>
              </div>

              <div className="col-lg-4">
                <div>
                  <img className='' src={require("../img/why3.png")} alt='' />
                  <h3 className='mt-lg-2 pt-1' style={{ fontSize: "24px", fontWeight: "600" }} > Return Policy </h3>
                  <h6 className='' style={{ color: "#999", fontSize: "14px" }} > No questions asked </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid p-0 d-md-none d-block" >
        </div>

      </section>


    </>

  )
}

export default Home