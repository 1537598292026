import React from 'react'
import { NavLink } from 'react-router-dom'

const UseAndThrow = () => {
    return (

        <>

            <section>
                <div className="container-fluid FontFamilyMontSerret">
                    <div className=' pt-3'>
                        <h6 className='mt-2 ms-lg-5 ps-lg-5' style={{ fontSize: "16px" }} > <NavLink to='/' className='text-decoration-none YESEVA' style={{ color: "black" }} > <span> Home </span>  </NavLink> <i class="fa-solid fa-angle-right"></i> <span> Home Care </span> <i class="fa-solid fa-angle-right"></i> <NavLink to='/useandthrow' className='text-decoration-none' style={{ color: "#00afc6" }} > <span> Use And Throw </span> </NavLink> </h6>
                    </div>
                    <div className="container p-3">
                        <div>
                            <h2 className='pt-3 pb-3' style={{ fontSize: "24px" }} >Showing Products for <b className='' style={{ color: "#00afc6" }} > Use And Throw </b> </h2>
                        </div>
                        <div className="row g-4 p-3">

                            <div className="col-lg-4 ">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Plastic.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Suzec Disposable (Use and Throw) Food Cooking Plastic Hand Gloves for Protection- Free Size (Transparent, Pack of 2, 200 Pieces) </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.0</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 175.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹175.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/PlasticGlass.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Tia Crafts Paper Glass Disposable, use and Throw Glass for Party, Paper Cups Eco Friendly, Safe and Hygienic for Juice,Coffee,Tea (White, 150 ml) - Pack of 150 Glasses </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.51</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 299.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 599.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Bowls.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Eha Set of 4 Earth-Friendly Multipurpose Bowls for Kitchen | 500 ml </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.46</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 372.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 689.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/PlasticPlate.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Signoraware Round Plastic Full Plate Set, Set of 3, Lemon Yellow </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.39</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 221.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 360.00 </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default UseAndThrow