import React from 'react'
import { NavLink } from 'react-router-dom'

const FreshVegitables = () => {
    return (

        <>

            <section>
                <div className="container-fluid FontFamilyMontSerret">
                    <div className=' pt-3'>
                        <h6 className='mt-2 ms-lg-5 ps-lg-5' style={{ fontSize: "16px" }} > <NavLink to='/' className='text-decoration-none YESEVA' style={{ color: "black" }} > <span> Home </span>  </NavLink> <i class="fa-solid fa-angle-right"></i> <span> Fresh Vegitables </span> <i class="fa-solid fa-angle-right"></i> <NavLink to='/freshvegitables' className='text-decoration-none' style={{ color: "#00afc6" }} > <span> Fresh Vegitables </span> </NavLink> </h6>
                    </div>
                    <div className="container p-2">
                        <div>
                            <h2 className='pt-3 pb-3' style={{ fontSize: "24px" }} > Showing Products for <b className='' style={{ color: "#00afc6" }} > Fresh Vegitables </b> </h2>
                        </div>
                        <div className="row g-4 p-3">
                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Tomatoes.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} >  Lorigun 15 Pcs Artificail Tomatoes  </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "14px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.25</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 325.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹300.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Onion.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Onion, 1kg </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.49</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 45.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹55.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/potato.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Pro Nature 100% Organic potato, 1kg </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.10</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 25.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 35.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Nimboo_Lemon.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Mphmi Plants Nimboo, Lemon Tree (Big Fruit, Grafted)- Plant </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.50</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 299.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 598.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Carrot.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "14px" }} > Fresho Carrot - Organically Grown (Loose), 1 kg </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.27</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 73.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 100.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Bhindi.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Lady Fingers (Bhindi) 250 Gm </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.28</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 61.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 84.00 </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default FreshVegitables