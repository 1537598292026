import React from 'react'

const TermsAndOffer = () => {
    return (
        <>

            <section>
                <div className="container FontFamilyMontSerret p-lg-3 p-4">
                    <div>
                        <h2 className='text-lg-center YESEVA mt-lg-3 pt-3 pb-lg-5' style={{ letterSpacing: "2px" }} > Terms And Offer </h2>
                    </div>
                    <div className="row mt-lg-5">
                        <p className=' YESEVA' style={{ fontSize: "30px" }} > 1. Deal of the Day Offer </p>
                        <div className='ms-lg-4' style={{ fontSize: "15px" }} >
                            <p> 1. Offer valid for the first 500 customers only </p>
                            <p> 2. One customer can buy 1 piece of the item only </p>
                            <p> 3. The item on offer will be valid at a certain bill value </p>
                            <p> 4. Offer valid from 7 am up to 11:59 pm on the day of offer </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TermsAndOffer