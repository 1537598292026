import React from 'react'
import { NavLink } from 'react-router-dom'

const MouthCare = () => {
    return (

        <>

            <section>
                <div className="container-fluid FontFamilyMontSerret">
                    <div className=' pt-3'>
                        <h6 className='mt-2 ms-lg-5 ps-lg-5' style={{ fontSize: "16px" }} > <NavLink to='/' className='text-decoration-none YESEVA' style={{ color: "black" }} > <span> Home </span>  </NavLink> <i class="fa-solid fa-angle-right"></i> <span> Personal Care </span> <i class="fa-solid fa-angle-right"></i> <NavLink to='/mouthcare' className='text-decoration-none' style={{ color: "#00afc6" }} > <span> Mouth Care </span> </NavLink> </h6>
                    </div>
                    <div className="container p-3">
                        <div>
                            <h2 className='pt-3 pb-3' style={{ fontSize: "24px" }} >Showing Products for <b className='' style={{ color: "#00afc6" }} > Mouth Care </b> </h2>
                        </div>
                        <div className="row g-4 p-3">

                            <div className="col-lg-4 ">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/ColgateMaxFresh.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Colgate MaxFresh 300g (150g x 2, Pack of 2)  </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.29</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 164.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 230.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/DABURLalDantManjan.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > DABUR Lal Dant Manjan Gingivitis, Bad Breath Tooth Powder - 150 g </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.6</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 89.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 95.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/ToothBrushes.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "14px" }} > Oral B Criss Cross - Family pack of 4 toothbrushes </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.49</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 156.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 320.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/BathingBar.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "14px" }} > Fiama Gel Bathing Bar Mega Celebration Pack, With 8 Unique Gel Bars & Skin Conditioners </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.35</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 416.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 640.00 </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default MouthCare