import React from 'react'
import { NavLink } from 'react-router-dom'

const FreshFruits = () => {
    return (

        <>

            <section>
                <div className="container-fluid FontFamilyMontSerret">
                    <div className=' pt-3'>
                        <h6 className='mt-2 ms-lg-5 ps-lg-5' style={{ fontSize: "16px" }} > <NavLink to='/' className='text-decoration-none YESEVA' style={{ color: "black" }} > <span> Home </span>  </NavLink> <i class="fa-solid fa-angle-right"></i> <span> Fresh Vegitables </span> <i class="fa-solid fa-angle-right"></i> <NavLink to='/freshfruits' className='text-decoration-none' style={{ color: "#00afc6" }} > <span> Fresh Fruits </span> </NavLink> </h6>
                    </div>
                    <div className="container p-2">
                        <div>
                            <h2 className='pt-3 pb-3' style={{ fontSize: "24px" }} > Showing Products for <b className='' style={{ color: "#00afc6" }} > Fresh Fruits </b> </h2>
                        </div>
                        <div className="row g-4 p-3">
                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Papaya.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Fresho Papaya - Raw/Boppaya Kaya, 1 kg  </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "14px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.27</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 45.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 63.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Bananas.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Fresho Banana - Raw, Organically Grown (Loose), 500 g </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.27</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 27.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹36.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Mango.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Fresho Mango - Raw Totapuri (Loose), 1 kg </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.27</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 100.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 135.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/Mini_Orange.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "15px" }} > Fresho Mini Orange Imported (Loose), 500 g </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.27</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 184.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 252.00 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-4 shadow h-100' style={{ borderRadius: "10px 10px 10px 10px", backgroundColor: "#fff" }}>
                                    <div className='text-center mb-3'>
                                        <img className='' style={{ height: "130px" }} src={require("../img/dalimb.png")} alt='' />
                                    </div>
                                    <div className='p-2'>
                                        <h6 className='' style={{ fontSize: "14px" }} > Pomegranate(dalimb)-Fresh | 1kg </h6>
                                    </div>
                                    <div className='p-2'>
                                        <img className='' style={{ height: "15px" }} src={require("../img/navigation.png")} alt='' />
                                        <span className='ms-2' style={{ fontSize: "13px", color: "#c65401", fontWeight: "600" }} >Flat Price Rs.0</span>
                                    </div>
                                    <div className='p-2'>
                                        <span className='' style={{ fontSize: "16px", color: "#434343", fontWeight: "500" }} > ₹ 140.00 </span><span className='ms-2 mrpUNDERLINE' style={{ fontSize: "11px", color: "#777", fontWeight: "600" }} > MRP ₹ 140.00 </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default FreshFruits