import React from 'react'

const Faq = () => {
    return (
        <>

            <section>
                <div className="container p-3 FontFamilyMontSerret" style={{ lineHeight: "1.8" }}>
                    <div>
                        <h2 className='text-lg-center YESEVA mt-lg-4 pt-4' style={{ letterSpacing: "2px" }} > FAQ </h2>
                    </div>

                    <div className='mt-lg-4 pt-4'>
                        <h2 className='mb-lg-2 pb-2 YESEVA' style={{ fontSize: "30px", letterSpacing: "2px" }} > General </h2>
                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > What is Jay Kisan Smart Mall? </p>
                            <p style={{ fontSize: "14px" }} > Jay Kisan Smart Mall, a Tata Enterprise aims to bring the ease of online shopping of your daily needs like grocery, bakery, fruits & vegetables and 1000’s of household products etc, directly via your mobile or web browser and delivery it right at your doorstep. In short, over 15,000 household products from a supermarket available at your doorstep within 3 hours – backed by the trust & love of Tata Group. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > What kind of products do you sell?  </p>
                            <p style={{ fontSize: "14px" }} > You can choose from over 15,000 products spread across various categories such as Grocery, Bakery, Fruits & Vegetables, Beverages, Personal Care products, Baby care products, Pet products and much more. Let us know if we are missing anything and we will surely add it to our always expanding catalogue. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > Do you deliver to my location? </p>
                            <div style={{ fontSize: "14px" }} >
                                <p> We deliver in select localities across the cities we are present in. You can check the same by entering your locality and checking if we deliver at your address. </p>
                                <p> Your locality is missing? Dont worry! We are adding more areas to deliver every week and would love to serve you very soon ! </p>
                            </div>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > What is the minimum order value? </p>
                            <p style={{ fontSize: "14px" }} > Voila! There is no minimum order value. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > Are there any hidden charges apart from product cost and taxes? </p>
                            <p style={{ fontSize: "14px" }} > No, except the Doorstep Service of Rs. 99/-[+ taxes]  charged on every order, Jay Kisan Smart Mall does not charge anything further to customers. We ensure our customers enjoy Market’s Lowest Prices and Best of Groceries spanning across the entire range. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > Can I track the status of my order? </p>
                            <p style={{ fontSize: "14px" }} > Yes, you can track the status of your order by logging into My Orders section. Also, we will keep notifying you with the status of your order as it processes. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > How can I make changes to my order before and after confirmation? </p>
                            <p style={{ fontSize: "14px" }} > Yes, you can edit quantities and products in your cart before checkout. If you’ve already placed your order, you can cancel and reorder your items. Or place a fresh order with newly added items. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > How is my order processed? </p>
                            <div style={{ fontSize: "14px" }} >
                                <p> As soon as you place an order, the following actions take place: </p>
                                <p> 1. You will get a notification through SMS of the confirmed order along with the expected delivery time. </p>
                                <p> 2. The order is routed to our partner Star Bazaar stores where our expert team of picker pick and carefully pack the order as per your delivery schedule. </p>
                                <p> 3. Order is packed and handed over to your delivery agent </p>
                                <p> 4. Delivery agents delivers the items to your delivery address as per scheduled time </p>
                                <p> Throughout the above stages, our Order Fulfilment Team continuously monitors the process so that you get the desired product within the time committed to you. In case the order or some items in the order cannot be processed for any reason whatsoever, the same will be informed to you before your order is dispatched from the store. </p>
                            </div>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > What are delivery slots? </p>
                            <p style={{ fontSize: "14px" }} > Delivery slots are time slots during which you will receive your order. You can select these as per your convenience at the time of placing the order. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > What is the warranty on goods? </p>
                            <p style={{ fontSize: "14px" }} > Except the warranty(ies) of the branded product(s), if any, given by the manufacturer of the goods, no additional warranty(ies) is/are given by the Company in any manner whatsoever. </p>
                        </div>

                    </div>

                    <div className='mt-lg-4 pt-4'>
                        <h2 className='mb-lg-2 pb-2 YESEVA' style={{ fontSize: "30px", letterSpacing: "2px" }} > Registration and account </h2>
                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > How do I register? </p>
                            <p style={{ fontSize: "14px" }} > You can register by clicking on the "Login" link at the top right corner of the homepage. Please provide the information in the form that appears. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > How can I login/register? </p>
                            <div style={{ fontSize: "14px" }} >
                                <p> You can login or Register using the following modes </p>
                                <p> 1. Google </p>
                                <p> 2. Login using your mobile and OTP </p>
                                <p> 3. Login using your email and password </p>
                            </div>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > Are there any charges for registration? </p>
                            <p style={{ fontSize: "14px" }} > No. Registration on Starquik.com is absolutely free. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > Do I have to necessarily register to shop on StarQuik? </p>
                            <p style={{ fontSize: "14px" }} > You can surf and add products to the cart without registration but only registered shoppers will be able to checkout and place orders. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > Do I need to place order from the location where i need delivery ? </p>
                            <p style={{ fontSize: "14px" }} > You can order from office and get delivery at your home or your can order from your home and get delivery in your office! You can also add multiple delivery addresses in your account, however delivery can be made to 1 address for 1 order and the address needs to be in our serviceable area. </p>
                        </div>

                        <div className='mt-lg-4'>
                            <p className=' YESEVA' style={{ fontSize: "18px" }} > How do I reset my password? </p>
                            <p style={{ fontSize: "14px" }} > You need to enter your email address on the Login page and click on forgot password. An email with a reset password link will be sent to your email address. With this, you can change your password. In case of any further issues please contact our customer support team. </p>
                            <p style={{ fontSize: "14px" }} > You can also login using your registered phone number. An OTP will be sent to the same which can be used to Login without Password. </p>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default Faq