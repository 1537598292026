import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Footer.css"

const Footer = () => {
    return (
        <>

            <section>
                <div className="container-fluid d-none d-md-block " style={{ backgroundColor: "#fff" }} >
                    <div className="container mt-5 p-2 p-lg-4">
                        <div className="row p-4 mt-lg-4 mb-lg-4 d-flex justify-content-around pt-lg-4 FontFamilyMontSerret">
                            <div className="col-lg-3">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className=' pb-3 YESEVA' style={{ fontSize: "15px", color: "#02c1ce", fontWeight: "800", letterSpacing: "1px", textTransform: "uppercase" }} > About </h4>
                                    <NavLink className='text-decoration-none' to='/about' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "24px", letterSpacing: "1px" }} > About Us </h6></NavLink>
                                    <NavLink className='text-decoration-none' to='/faq' > <h6 className='FOOTERHOVER' style={{ fontSize: "15px", lineHeight: "24px" }} > FAQ </h6> </NavLink>
                                    <NavLink className='text-decoration-none' to='/termsandoffer' > <h6 className='FOOTERHOVER' style={{ fontSize: "15px", lineHeight: "24px,", letterSpacing: "1px" }} > Terms Of Offer </h6> </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className=' pb-3 YESEVA' style={{ fontSize: "15px", color: "#02c1ce", fontWeight: "800", letterSpacing: "1px", textTransform: "uppercase" }} > Quick Links </h4>
                                    <NavLink className='text-decoration-none' to='/' > <h6 className='FOOTERHOVER' style={{ fontSize: "15px", lineHeight: "25px", letterSpacing: "1px" }} > Locations We Ship to </h6></NavLink>
                                    <NavLink className='text-decoration-none' to='/Visit' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "25px", letterSpacing: "1px" }} > Visit Shop </h6> </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-4 ">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className=' pb-3 YESEVA' style={{ fontSize: "15px", color: "#02c1ce", fontWeight: "800", letterSpacing: "1px", textTransform: "uppercase" }} > Contact us </h4>
                                    <div className=''>
                                        <NavLink className="text-decoration-none text-start" to=''>
                                            <div className='mb-2 text-start' style={{ color: "#02c1ce", fontSize: "16px", fontWeight: "600", letterSpacing: "1px" }}> Email  :  <span className='FOOTERHOVER' style={{ fontWeight: "200", }} > jaykisansmartmall@gmail.com </span>
                                            </div>
                                        </NavLink>
                                        <NavLink className="text-decoration-none " to=''>
                                            <div className='mb-2' style={{ color: "#02c1ce", fontSize: "16px", fontWeight: "600", letterSpacing: "2px" }} > Contact :  <span className='FOOTERHOVER' style={{ fontWeight: "200", }} >  8551964221 </span>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className='pb-3 YESEVA' style={{ fontSize: "15px", color: "#02c1ce", fontWeight: "800", letterSpacing: "1px", textTransform: "uppercase" }} > FOLLOW US </h4>
                                    <div className='pb-4 mt-lg-3'>
                                        <div className=''>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-facebook " style={{ color: "#02c1ce", fontSize: "40px" }} ></i></NavLink>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-whatsapp ms-4" style={{ color: "#02c1ce", fontSize: "40px" }} ></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-md-none d-block "  >
                    <div className="container">
                        <div className='mt-5  text-center'>
                            <div className='mb-4'>
                                <NavLink to="/"><img className='' style={{ height: "7rem" }} src={require("../img/LOGONEW.png")} alt="Jay Kisan Smart Mall_logo" /></NavLink>
                            </div>
                            <h2 >Welcome to iCloudSoft</h2>
                        </div>
                        <div className="row p-3 mt-4 mb-lg-4 d-flex justify-content-around align-items-center pt-lg-4 FontFamilyMontSerret">
                            <div className="col-lg-3">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className=' pb-3 YESEVA' style={{ fontSize: "15px", color: "#02c1ce", fontWeight: "800", letterSpacing: "1px", textTransform: "uppercase" }} > About </h4>
                                    <NavLink className='text-decoration-none' to='/about' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "24px", letterSpacing: "1px" }} > About Us </h6></NavLink>
                                    <NavLink className='text-decoration-none' to='/faq' > <h6 className='FOOTERHOVER' style={{ fontSize: "15px", lineHeight: "24px" }} > FAQ </h6> </NavLink>
                                    <NavLink className='text-decoration-none' to='/termsandoffer' > <h6 className='FOOTERHOVER' style={{ fontSize: "15px", lineHeight: "24px,", letterSpacing: "1px" }} > Terms Of Offer </h6> </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className=' pb-3 YESEVA' style={{ fontSize: "15px", color: "#02c1ce", fontWeight: "800", letterSpacing: "1px", textTransform: "uppercase" }} > Quick Links </h4>
                                    <NavLink className='text-decoration-none' to='/' > <h6 className='FOOTERHOVER' style={{ fontSize: "15px", lineHeight: "25px", letterSpacing: "1px" }} > Locations We Ship to </h6></NavLink>
                                    <NavLink className='text-decoration-none' to='/Visit' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "25px", letterSpacing: "1px" }} > Visit Shop </h6> </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-3 ">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className=' pb-3 YESEVA' style={{ fontSize: "15px", color: "#02c1ce", fontWeight: "800", letterSpacing: "1px", textTransform: "uppercase" }} > Contact us </h4>
                                    <div className=''>
                                        <NavLink className="text-decoration-none text-start" to=''>
                                            <div className='mb-2 text-start' style={{ color: "#02c1ce", fontSize: "16px", fontWeight: "600", letterSpacing: "1px" }}> Email  :  <span className='FOOTERHOVER' style={{ fontWeight: "200", }} > jaykisansmartmall@gmail.com </span>
                                            </div>
                                        </NavLink>
                                        <NavLink className="text-decoration-none " to=''>
                                            <div className='mb-2' style={{ color: "#02c1ce", fontSize: "16px", fontWeight: "600", letterSpacing: "2px" }} > Contact :  <span className='FOOTERHOVER' style={{ fontWeight: "200", }} >  8551964221 </span>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className='pb-3 YESEVA' style={{ fontSize: "15px", color: "#02c1ce", fontWeight: "800", letterSpacing: "1px", textTransform: "uppercase" }} > FOLLOW US </h4>
                                    <div className='pb-4 mt-lg-3'>
                                        <div className=''>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-facebook " style={{ color: "#02c1ce", fontSize: "40px" }} ></i></NavLink>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-whatsapp ms-4" style={{ color: "#02c1ce", fontSize: "40px" }} ></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section >

            <section>
                <div className="container-fluid d-none d-md-block " style={{ backgroundColor: "#202B41", }}>
                    <div className=' text-center p-3 text-white ' >
                        Copyright © 2008-2023 Jay Kisan Smart Mall, Inc. All rights reserved. Various trademarks held by their respective owners.
                    </div>
                </div>

                <div className="container-fluid p-0 d-md-none d-block" >
                </div>

            </section>

        </>
    )
}

export default Footer