import React from 'react'

const About = () => {
  return (
    <>

      <section>
        <div className="container p-3 FontFamilyMontSerret" style={{ lineHeight: "1.8" }}>
          <div className='mt-lg-5 pt-4'>
            <h2 className=' pb-2 YESEVA' style={{ fontSize: "35px" }} > About Us </h2>
            <div className='mt-3' style={{ fontSize: "15px" }} >
              <p> Welcome to Jay Kisan Smart Mall, a one stop destination for all your Grocery needs! </p>
              <p> We aim to make grocery shopping fun, easy & convenient for you! Shop from our range of Thousands of products and get Everyday Sabse Sasta Prices! </p>
              <p> You don’t have to wait for festivals or special occasions, we treat you with Lowest Prices throughout the year. </p>
              <p> When you shop with us, you will see Big Savings on your bill #BachateRaho </p>
              <p> Along with Premium Quality Groceries from the Best of Brands, you can plan healthy home-cooked meals with our Farm fresh Fruits and Vegetables including seasonal and exotic ones. </p>
              <p> While you enjoy shopping from the comfort of your homes on Jay Kisan Smart Mall, you can also visit your nearest Star Bazaar stores and experience shopping in store. With Star, you can shop Anywhere, Anytime! </p>
            </div>
          </div>

          <div className='mt-lg-5 pt-4'>
            <h2 className='mb-lg-2 pb-2 YESEVA' style={{ fontSize: "30px" }} > Why shop with us ? </h2>
            <div className='mt-lg-4'>
              <p className=' YESEVA' style={{ fontSize: "20px" }} > Our Range </p>
              <p style={{ fontSize: "15px" }} > Shop from our wide range of 5000+ products across categories including Staples, Fresh Fruits and Vegetables, Snacks, Beverages, Home Care, Personal Care, Dairy Products, Non-Veg, Frozen food, Kitchen Ware, International Range and many more at the best prices. </p>
            </div>

            <div className='mt-lg-5'>
              <p className=' YESEVA' style={{ fontSize: "20px" }} > Prices  </p>
              <p style={{ fontSize: "15px" }} > Enjoy the Best Deals, Lowest Prices & Biggest Savings everyday #BachateRaho. Get everything under MRP, at market’s best prices & hottest offers on all the leading brands like Aashirwaad, Surf Excel, Red Label, Amul, Kissan, Britannia, Nestle, Cadbury, Haldiram, Dove, Sunsilk, Nivea and Vaseline and many many more.   </p>
            </div>

            <div className='mt-lg-5'>
              <p className=' YESEVA' style={{ fontSize: "20px" }} > Own Brands </p>
              <div style={{ fontSize: "15px" }} >
                <p> You can also shop for the best food and non-food products from our own TATA brands: </p>
                <p> Fabsta - our home-grown food brand </p>
                <p> Klia- Our home-grown home care brand </p>
                <p> Skye- Our home-grown personal care brand </p>
              </div>
            </div>

            <div className='mt-lg-5'>
              <p className=' YESEVA' style={{ fontSize: "20px" }} > Our Return Policy </p>
              <p style={{ fontSize: "15px" }} > This is the best part. We have a NO questions asked return policy. Just reach out to us at the time of the order delivery or even upto 48 hours later and we will take the items back - No questions asked. </p>
            </div>

            <div className='mt-lg-5'>
              <p className=' YESEVA' style={{ fontSize: "20px" }} > Our Range </p>
              <p style={{ fontSize: "15px" }} > Shop from our wide range of 5000+ products across categories including Staples, Fresh Fruits and Vegetables, Snacks, Beverages, Home Care, Personal Care, Dairy Products, Non-Veg, Frozen food, Kitchen Ware, International Range and many more at the best prices. </p>
            </div>

            <div className='mt-lg-5'>
              <p className=' YESEVA' style={{ fontSize: "20px" }} > Our Farmers </p>
              <div style={{ fontSize: "15px" }} >
                <p> We know how much you love fresh food. We source over 80% of the vegetables and over 65% of the fruits directly from over 800 farmers across the country. And we bring it from farms to our stores in less than 24 hours and from stores to your doorstep within few hours. </p>
                <p> Our farmers are our partners. They share the same principles as we do, which is providing the best quality seasonal produce for our customers. AT STAR, we put quality first and believe in offering the best of fresh produce loved by all our customers. </p>
                <p> We believe in supporting the nation, by focusing on locally grown products that cater to our customers’ demands. Our farmers are a huge part of our STAR family and we believe in giving them a tremendous share of respect by showing their names and faces. It also helps us create a connection between our farmers & customers. And with an efficient supply chain, we bring their love, freshness & flavour to your plates from various parts of the country. </p>
              </div>
            </div>

          </div>
        </div>
      </section>
      
    </>
  )
}

export default About