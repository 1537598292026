import React from 'react'
import { NavLink } from 'react-router-dom'

const Visit = () => {
    return (
        <>

            <section>
                <div className="container FontFamilyMontSerret p-lg-5">
                    <div>
                        <h2 className='text-lg-center YESEVA mt-lg-3 pt-3 pb-lg-4' style={{ letterSpacing: "2px" }} > Visit To Shop </h2>
                    </div>
                    <div className='row d-flex justify-content-around text-lg-center mt-lg-5'  >
                        <div className="col-lg-4">
                            <div className='text-lg-center mb-2'>
                                <i className="fa-solid fa-phone text-lg-center fs-4 mb-3"></i>
                            </div>
                            <h4 className='fs-4 text-lg-center mb-3'> Call Us </h4>
                            <NavLink className='text-decoration-none text-dark ' style={{lineHeight:"1.6"}} to='tel:8551964221, 8766779267'> <h6 className='mt-3 fs-5 ps-4 pe-4'>+91 8551964221, +91 8766779267</h6></NavLink>
                        </div>

                        <div className="col-lg-4 ">
                            <div className='text-lg-center mb-2'>
                                 <i className="fa-solid fa-location-dot fs-2 mb-2"></i> 
                            </div>
                            <h4 className=' fs-4 mb-3'>Address</h4>
                            <h6 className='fs-5  text-lg-center ' style={{lineHeight:"1.6"}} >Jay Kisan Smart Mall Technology Office No 601, 6th Floor, Lotus Building, Opp Joshi Railway Museum, Kothrud, Pune 411038</h6>
                        </div>

                        <div className="col-lg-4">
                            <div className='text-lg-center'>
                                <i className="fa-solid fa-envelope fs-2 mb-2"></i><br />
                            </div>
                            <h4 className='fs-4 text-lg-center '>Email</h4>
                            <NavLink className='text-decoration-none text-dark ' style={{lineHeight:"1.6"}} to="mailto:info@soft.co.in"> <h6 className='text-lg-center fs-5'>info@cloudsoft.co.in</h6></NavLink>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default Visit